import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Divider from "../components/Divider";
import Footer from "../components/Footer";
import Header from "../components/Header";


const pageStyles = {
  color: "#232129",
  padding: 0,
  margin: 0,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const linkStyle = {
  color: 'black',
  textDecoration: 'none',
  wordWrap: 'break-word',
  textAlign: 'left',
}

const buttonStyle = {
  padding: '8px 32px',
  backgroundColor: '#214191',
  color: 'white',
  height: 44,
  outline: 'none',
  border: 'none'
}

const brandStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}
const partnerBrandImageStyles = {
  marginTop: 20
}

const offeringStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}

const taglineStyles = {
  textAlign: 'center',
  margin: 'auto',
  fontFamily: 'Lora, serif',
  fontStyle: 'italics',
  fontWeight: 'normal',
  fontSize: '1.5rem',
}

const footerStyles = {
  backgroundColor: '#E9ECF4',
  padding: '40px 0',
}

// markup
const IndexPage = () => {

  const [isDesktop, setDesktop] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 768)
    }
  }, [])

  const updateMedia = () => {
    setDesktop(window.innerWidth > 768)
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia)
    return () => window.removeEventListener("resize", updateMedia)
  })

  return (
    <>
      <main style={pageStyles}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dhawan Enterprises</title>
          <meta name="description" content="A prominent manufacturing and wholesale trader for pumps and cables" />
        </Helmet>

        {/* Hero Container */}
        <div className="introContainer">
          {/* Header Container */}
          <div style={{ height: 50 }} className="container">
            <Header showWhatsapp={isDesktop} />
          </div>

          <div className="hero container">
            <div style={{ width: isDesktop ? '35%' : '100%' }}>
              <h1 style={{ fontFamily: 'Lora, serif', fontSize: isDesktop ? '3rem' : '2.5rem', margin: '20px 0' }}>Wholesalers in pumps and cables</h1>
              <p>We are a prominent manufacturing and wholesale trader for a wide range of high quality products.</p>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start', flexWrap: 'wrap' }}>
                <AnchorLink to="/#offerings" className="anchor">
                  <button style={{ ...buttonStyle, cursor: 'pointer' }}>Learn More</button>
                </AnchorLink>
                <span style={{ marginTop: 20 }}>
                  <StaticImage src="../images/location-icon.png" height={20} style={{ marginRight: 5 }} />
                  <a href="https://goo.gl/maps/2AEByhkSUL6A1rdE8" style={linkStyle}>
                    6/104/1, Belanganj Chaurha, Agra, Uttar Pradesh
                  </a>
                </span>
                {
                  isDesktop ? <></> :
                    <div style={{ marginTop: 20 }}>
                      <StaticImage src="../images/whatsapp-icon.png" alt="Whatsapp Icon" height={20} style={{ marginRight: 5 }} />
                      <a href="https://api.whatsapp.com/send?phone=+919837200100&text=Hey" style={linkStyle}>9837200100</a>
                      <span>, </span>
                      <a href="https://api.whatsapp.com/send?phone=+919368020100&text=Hey" style={linkStyle}>9368020100</a>
                    </div>
                }
              </div>
            </div>
            <div style={{ width: isDesktop ? '50%' : '100%', marginTop: isDesktop ? 0 : 30 }}>
              <StaticImage src="../images/hero-pump-image.png" objectFit={'cover'} style={{ padding: 10 }} />
            </div>
          </div>
        </div>

        {/* Partner Brands */}
        <div style={{ ...brandStyles }} className="container">
          <h2 style={{ fontFamily: 'Lora, serif', fontSize: '2rem', margin: '0 0 30px 0', textAlign: 'center' }}>Our Partner Brands</h2>

          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '40px', justifyContent: 'center' }}>
            <StaticImage src='../images/brands/azuka.png' alt='Azuka' height={40} style={partnerBrandImageStyles} objectFit={'cover'} />
            <StaticImage src='../images/brands/crompton.png' alt='Crompton' height={40} style={partnerBrandImageStyles} objectFit={'cover'} />
            <StaticImage src='../images/brands/cromson.png' alt='Cromson' height={40} style={partnerBrandImageStyles} objectFit={'cover'} />
            <StaticImage src='../images/brands/johnson.png' alt='Johnson' height={40} style={partnerBrandImageStyles} objectFit={'cover'} />
            <StaticImage src='../images/brands/latteys.png' alt='Latteys' height={40} style={partnerBrandImageStyles} objectFit={'cover'} />
            <StaticImage src='../images/brands/luni.png' alt='Luni' height={40} style={partnerBrandImageStyles} objectFit={'cover'} />
            <StaticImage src='../images/brands/rasoiking.png' alt='Rasoi King' height={40} style={partnerBrandImageStyles} objectFit={'cover'} />
            <StaticImage src='../images/brands/usha.png' alt='Usha' height={40} style={partnerBrandImageStyles} objectFit={'cover'} />
            <StaticImage src='../images/brands/ashok-leyland-logo.jpeg' alt='Ashok Leyland' height={40} style={partnerBrandImageStyles} objectFit={'cover'} />
          </div>
        </div>

        <Divider />

        {/* Offerings */}
        <div style={{ ...offeringStyles }} className="container" id="offerings">
          <h2 style={{ fontFamily: 'Lora, serif', fontSize: '2rem', margin: '0 0 30px 0', textAlign: 'center' }}>Our Offerings</h2>

          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <StaticImage src='../images/offerings/offering_1.png' alt='Offering' height={200} objectFit={'contain'} className="offeringImage" />
            <StaticImage src='../images/offerings/offering_2.png' alt='Offering' height={200} objectFit={'contain'} className="offeringImage" />
            <StaticImage src='../images/offerings/offering_3.png' alt='Offering' height={200} objectFit={'contain'} className="offeringImage" />
            <StaticImage src='../images/offerings/offering_4.png' alt='Offering' height={200} objectFit={'contain'} className="offeringImage" />
            <StaticImage src='../images/offerings/offering_5.png' alt='Offering' height={200} objectFit={'contain'} className="offeringImage" />
            <StaticImage src='../images/offerings/offering_6.png' alt='Offering' height={200} objectFit={'contain'} className="offeringImage" />
            <StaticImage src='../images/offerings/offering_7.png' alt='Offering' height={200} objectFit={'contain'} className="offeringImage" />
            <StaticImage src='../images/offerings/offering_8.png' alt='Offering' height={200} objectFit={'contain'} className="offeringImage" />
            <StaticImage src='../images/offerings/offering_9.jpeg' alt='Offering' height={250} objectFit={'contain'} className="offeringImage" />
          </div>
        </div>

        <Divider />

        {/* Tagline */}
        <div className="container">
          <h3 style={taglineStyles}>Delivering on our promise of quality products since 1994.</h3>
        </div>
      </main>

      <footer style={footerStyles}>
        <div className="container">
          <Footer isDesktop={isDesktop} />
        </div>
      </footer>
    </>
  )
}

export default IndexPage
