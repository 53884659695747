import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";


const headerStyles = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
};

const linkStyle = {
    color: 'black',
    textDecoration: 'none'
};

const Header = ({ showWhatsapp }) => {
    return (
        <>
            <div style={headerStyles}>
                <StaticImage src="../images/logo.png" alt="Dhawan Enterprises Estd. 1994" height={50} objectFit={'cover'} />
                {showWhatsapp ?
                    <div>
                        <StaticImage src="../images/whatsapp-icon.png" alt="Whatsapp Icon" height={20} style={{ marginRight: 5 }} />
                        <a href="https://api.whatsapp.com/send?phone=+919837200100&text=Hey" style={linkStyle}>9837200100</a>
                        <span>, </span>
                        <a href="https://api.whatsapp.com/send?phone=+919368020100&text=Hey" style={linkStyle}>9368020100</a>
                    </div>
                    : <></>
                }
            </div>
        </>
    )
};

export default Header;