import * as React from "react";

const dividerStyles = {
    width: '100vw',
    height: 1,
    color: '#CDDCEE',
    backgroundColor: '#CDDCEE',
    borderWidth: 0,
}

const Divider = () => {
    return (
        <>
            <hr style={dividerStyles} />
        </>
    )
};

export default Divider;