import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

const linkStyle = {
    color: 'black',
    textDecoration: 'none'
};

const Footer = ({ isDesktop }) => {
    return (
        <>
            <div className="footer">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'start' }}>
                    <StaticImage src="../images/logo.png" alt="Dhawan Enterprises Estd. 1994" height={40} objectFit='cover' />
                    <a href="https://goo.gl/maps/2AEByhkSUL6A1rdE8" style={{ ...linkStyle, marginTop: 20 }}>
                        6/104/1, Belanganj Chaurha, Agra, Uttar Pradesh
                    </a>
                </div>
                <div style={{ marginTop: isDesktop ? 0 : 20 }}>
                    <StaticImage src="../images/whatsapp-icon.png" alt="Whatsapp Icon" height={20} style={{ marginRight: 5 }} />
                    <a href="https://api.whatsapp.com/send?phone=+919837200100&text=Hey" style={linkStyle}>9837200100</a>
                    <span>, </span>
                    <a href="https://api.whatsapp.com/send?phone=+919368020100&text=Hey" style={linkStyle}>9368020100</a>
                </div>
            </div>
        </>
    )
};

export default Footer;